<template>
  <v-card id="account-setting-card">
    <v-card-title class="justify-center">
      <span class="text-h5"><strong>RECIPIENT INFORMATION FORM</strong></span>
    </v-card-title>
  
    <!-- tabs item -->
     <v-tabs v-model="active_tab">
      <v-tab
        key="0"
        :disabled="readOnlyField === true"
      >
        <span>OS (oculus sinister)</span>
      </v-tab>
      <v-tab
        key="1"
        :disabled="readOnlyField1 === true"
      >
        
        <span>OD (oculus dextrus)</span>
      </v-tab>
      </v-tabs>



    <v-tabs-items v-model="active_tab">

      <v-tab-item key="0">
        <RecipientOS/>
      </v-tab-item>

      <v-tab-item key="1">
        <RecipientOD />
      </v-tab-item>
      
    </v-tabs-items>
  </v-card>
</template>

<script>


// demos
import RecipientSevice from '@/service/Recipient.service'
import RecipientOS from './RecipientOS.vue'
import RecipientOD from './RecipientOD.vue'



export default {
  components: {
    RecipientOS,
    RecipientOD,
   
  },
  setup() {
    return {
      // tab: "tab2",
      readOnlyField: false,
      readOnlyField1: false,
      active_tab: 0,
    }
  },
  watch: {
    options: {
      handler() {
        this.Recipientview()
      },
      deep: true,
    },
  },

  async mounted() {
    this.Recipientview()
  },
   methods:{
      async Recipientview() {
      const DonorId = this.$route.params.donor_id
      const service = new RecipientSevice()
      let response = await service.RecipientDetail_OD(DonorId)
      // console.log("OSSS", response)
       if(response.os_id == null){
        //console.log("shubham")
        this.readOnlyField = true
        this.active_tab = "1"
      }if(response.od_id == null){
        //console.log("bhati")
        this.readOnlyField1 = true
        this.active_tab = "0"
      }if(response.os_id == null && response.od_id == null){
        //console.log("bhati new")
        this.readOnlyField = true
        this.readOnlyField1 = true
        this.active_tab = "2"
      }
  }
}
}

</script>

	

