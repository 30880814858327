const axios = require("axios").default;

const baseUrl = 'https://api.stage.ebsr.in/'

const api = axios.create({
    baseURL: baseUrl, headers: {
        "Content-type": "application/json",
        'Cache-Control': 'no-cache',
        'Pragma': 'no-cache',
        'Expires': '0',
    }
});

export {
    api
}

